exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-motion-graphics-jsx": () => import("./../../../src/pages/motion-graphics.jsx" /* webpackChunkName: "component---src-pages-motion-graphics-jsx" */),
  "component---src-pages-photo-editing-jsx": () => import("./../../../src/pages/photo-editing.jsx" /* webpackChunkName: "component---src-pages-photo-editing-jsx" */),
  "component---src-pages-photo-manipulation-jsx": () => import("./../../../src/pages/photo-manipulation.jsx" /* webpackChunkName: "component---src-pages-photo-manipulation-jsx" */),
  "component---src-pages-web-development-jsx": () => import("./../../../src/pages/web-development.jsx" /* webpackChunkName: "component---src-pages-web-development-jsx" */),
  "component---src-templates-project-details-jsx": () => import("./../../../src/templates/project-details.jsx" /* webpackChunkName: "component---src-templates-project-details-jsx" */)
}

